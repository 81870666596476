.RMSDialogCard {
  @apply relative bg-white rounded-lg shadow;

  &:global {
    animation: fadeInUp 0.5s;
  }

  &.close {
    &:global {
      animation: fadeOutDown 1s;
    }
  }

  .header {
    @apply flex items-center justify-between p-4 md:p-5 border-b rounded-t;

    .title {
      @apply text-xl font-semibold;
    }

    .closeButton {
      @apply text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center;
    }
  }

  .content {
    @apply pt-8 md:pt-6 space-y-4;
  }

  .footer {
    @apply flex justify-end items-center p-4 md:p-5 border-t border-gray-200 rounded-b;
  }
}
