.section {
  @apply w-full;

  .content {
    @apply max-w-screen-lg mx-auto px-4 sm:px-6 flex items-center;

    .inner {
      @apply py-4 lg:py-8 w-full;

      .title {
        @apply mb-4 w-full text-center;
      }

      .subtitle {
        @apply mb-8 w-full text-gray-600 text-center;
      }

      .testimonials {
        @apply w-full grid grid-cols-1 md:grid-cols-3 gap-4;

        .testimonial {
          @apply w-full h-full;
        }
      }

      .buttonContainer {
        @apply w-full mt-4;

        .button {
          @apply w-full;
        }
      }
    }
  }
}
