.container {
  @apply w-full;

  .content {
    @apply block md:flex items-center md:space-x-4;

    .image {
      @apply w-52 h-52 rounded-full my-4 mx-auto;

      &.left {
        @apply md:mr-4 md:order-first;
      }
      &.right {
        @apply md:ml-4 md:order-last;
      }
    }

    .text {
      @apply w-full text-center;

      &.left {
        @apply md:text-left;
      }

      &.right {
        @apply md:text-right;
      }

      .name {
        @apply text-2xl font-bold;
      }

      .position {
        @apply text-color-primary-400 text-lg;
      }

      .description {
        @apply text-gray-700 mt-4;
      }
    }
  }

  .hr {
    @apply border-gray-300 mt-4;
  }
}
