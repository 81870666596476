.container {
  @apply bg-gray-100 hover:bg-gray-200 p-4 md:p-8 rounded-lg h-full;

  &:hover {
    .inner {
      .imageContainer {
        .image {
          @apply grayscale-0;
        }
      }
    }
  }

  .inner {
    @apply flex flex-col h-full;

    .imageContainer {
      @apply flex justify-center items-center;

      .image {
        @apply h-28 w-auto object-contain md:grayscale;
      }
    }

    .title {
      @apply w-full text-center text-lg font-medium mt-4;
    }

    .subtitle {
      @apply w-full text-center text-sm font-light text-gray-600 mt-4;
    }

    .linkContainer {
      @apply mt-auto pt-4 w-full text-center;

      .link {
        @apply text-color-primary-500 inline-flex items-center;

        .svg {
          @apply rtl:rotate-180 w-3.5 h-3.5 ms-2;
        }
      }
    }
  }
}
