.footer {
  @apply bg-white py-4 w-full;

  .container {
    @apply w-full max-w-screen-xl mx-auto p-4 md:py-8;

    .imageContainer {
      @apply sm:flex sm:items-center sm:justify-between;

      .link {
        @apply flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse;

        .image {
          @apply h-8;
        }

        .text {
          @apply self-center text-xl font-semibold whitespace-nowrap;
        }
      }

      .list {
        @apply flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 list-none;

        .element {
          @apply hover:underline me-4 md:me-6;
        }
      }
    }

    .hr {
      @apply my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8;
    }

    .bottom {
      @apply block text-sm text-gray-500 sm:text-center;

      .element {
        @apply hover:underline;
      }
    }
  }
}
