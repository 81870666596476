.ServicePage {
  .relatedTechnologies {
    @apply max-w-screen-lg w-full mx-auto px-4 sm:px-6 items-center;

    .title {
      @apply w-full mb-4;
    }

    .badge {
      @apply block;
    }
  }
}
