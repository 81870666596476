.RMSBreadcrumb {
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > :last-child {
    font-weight: bold;
    color: var(--rms-color-primary-500);
  }
}
