.BlogPage {
  @apply max-w-full min-h-screen;

  .BlogPage__Header {
    @apply text-center px-16;

    h1 {
      @apply text-center mb-16;
    }
  }

  &.onlyText {
    padding: 64px 0;

    @media (max-width: theme('screens.md')) {
      padding: 32px 0;
    }
  }
}

.IndexContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px;
}
