@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'styles/variables.css';
@import 'animate.css';
@import 'styles/rms-styles/rms-styles';

/* AOS */
@import 'aos/dist/aos.css';

@layer base {
  ul {
    @apply text-left list-disc pl-8;
  }

  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-3xl font-bold;
  }
  h3 {
    @apply text-2xl font-semibold;
  }
  h4 {
    @apply text-xl font-semibold;
  }
  h5 {
    @apply text-lg font-bold;
  }

  button,
  .button {
    @apply rounded-lg px-5 py-2.5 cursor-pointer;

    &.primary {
      @apply bg-color-primary-500 text-color-light hover:bg-color-primary-700;
    }

    &.secondary {
      @apply bg-transparent text-color-primary-500 border-color-primary-500 border-2;

      &.light {
        @apply text-color-light border-color-light;
      }
    }
  }

  p {
    @apply break-words;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes slideRightToLeft {
  0% {
    transform: translateX(0); /* Start position: completely off to the right */
  }
  100% {
    transform: translateX(-50%); /* End position: completely off to the left */
  }
}

.MaxWidthContainer {
  @apply w-full;
}

.MaxWidthContainer:not(.IgnoreMaxWidth) {
  @apply max-w-screen-xl;
}

.MaxWidthOuterContainer {
  @apply w-full flex flex-col items-center justify-center;
}

.react-syntax-highlighter-line-number {
  color: var(--rms-color-primary-500) !important;
}

/* Link */
a:not(.custom) {
  @apply text-color-primary-500 hover:text-color-primary-700;
}
