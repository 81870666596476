.container {
  @apply w-full;

  .banner {
    @apply absolute h-72 md:h-80 inset-0 flex items-center justify-center mt-14 z-0;

    .overlay {
      @apply bg-gray-400 opacity-70;
    }
  }

  .titleContainer {
    @apply flex align-middle justify-center h-72 z-10;

    .title {
      @apply text-center max-w-2xl text-3xl sm:text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl m-auto py-10 px-4;
    }
  }

  .content {
    @apply max-w-5xl mx-auto px-4 sm:px-6;

    .contentInner {
      @apply mt-4 md:mt-8;
    }
  }
}
