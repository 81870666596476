.container {
  @apply flex flex-col justify-center items-center w-full relative mb-4;

  .content {
    @apply max-w-screen-lg w-full mx-auto px-4 sm:px-6 flex flex-col md:flex-row justify-between;

    .breadcrumb {
      @apply mb-2 md:mb-0;
    }

    .date {
      @apply text-left text-color-primary-500 font-light float-right;
    }
  }
}
