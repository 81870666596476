.rms-hover-scale {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.rms-hover-scale-small {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.9);
  }
}

.rms-hover-scale-rotate {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1) rotate(5deg);
  }
}

.rms-hover-scale-rotate-small {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(0.9) rotate(-5deg);
  }
}

.rms-hover-move-up {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(
      -5px
    ); /* Adjust the value as needed for the desired movement */
  }
}

.rms-hover-move-left {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateX(-10px);
  }
}

.rms-hover-move-right {
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateX(10px);
  }
}

@keyframes textPulsate {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }
  50% {
    text-shadow: 0 0 20px #fff;
  }
  100% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7);
  }
}
