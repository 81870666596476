.rms-primary-color {
  color: var(--rms-color-primary-500);
}

.rms-primary-button {
  background: var(--rms-color-primary-500);
  color: var(--rms-color-white);
  border: 1px solid var(--rms-color-primary-500);
  border-radius: 16px;
  padding: 12px;
  font-size: 1.2em;

  a {
    color: var(--rms-color-white);
    text-decoration: none;
  }

  &.outlined {
    border: 1px solid var(--rms-color-light);
  }
}

.rms-clickable {
  cursor: pointer;
}
