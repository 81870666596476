.container {
  @apply flex flex-col justify-center items-center w-full relative;

  .backgroundImage {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: -1;
  }

  .content {
    @apply max-w-screen-lg w-full mx-auto px-4 sm:px-6 flex items-center;

    .RichTextElement {
      @apply pb-4 lg:pb-8 w-full;

      & > *:not(:last-child) {
        @apply mb-4;
      }

      ol {
        @apply list-decimal list-inside;

        li {
          @apply marker:font-bold mb-2;
        }
      }
    }
  }
}
