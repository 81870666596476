.rootPageLoading {
  @apply fixed top-0 w-screen h-screen bg-color-primary-100;
  z-index: 8000;

  .loadingMidContainer {
    @apply fixed top-0 bottom-0 left-0 right-0 m-auto flex flex-col items-center justify-center gap-4;

    img {
      @apply bg-white bg-opacity-25 p-4 rounded-full;
      width: 200px;
    }

    .title {
      @apply text-color-primary-700 text-center mt-6 text-3xl font-semibold;
    }
  }
}

.errorContainer {
  @apply px-4 py-32 md:px-32 flex flex-col items-center justify-center max-w-screen-md gap-4 text-gray-800 text-center;

  .errorTitle {
    @apply font-semibold text-4xl;
  }

  button {
    @apply text-2xl mt-4;
  }

  .errorSubtitle {
    @apply text-center text-gray-600 font-light text-lg;
  }

  .errorContent {
    @apply text-center text-gray-600 font-light text-sm;
  }
}
