.RMSGalleryBlock {
  @apply pb-4 lg:pb-8;

  .Inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: center;

    .ImgContainer {
      height: 277px;
      max-width: 277px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      img {
        object-fit: cover;
      }
    }
  }
}
