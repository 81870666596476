.DownloadElement {
  @apply flex flex-row justify-center items-center gap-4;
  max-width: 500px;

  a {
    flex: 1;

    &:global {
      animation: fadeInUp 1s;
    }

    &.appStoreButton img {
      width: 100%;
      padding: 8px;
    }
  }
}
