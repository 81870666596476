.container {
  @apply bg-white p-4 md:p-8 rounded-lg h-full;

  .inner {
    @apply flex flex-col h-full;

    .title {
      @apply w-full text-center text-lg font-medium mt-4;
    }

    .rating {
      @apply flex;

      .ratingStars {
        @apply flex items-center me-2;

        .ratingStar {
          @apply w-4 h-4 ms-1;

          &.checked {
            @apply text-color-primary-500;
          }

          &.unchecked {
            @apply text-gray-300;
          }
        }
      }

      .ratingText {
        @apply text-sm text-gray-500 font-light;
      }
    }

    .text {
      @apply w-full text-left text-sm text-gray-600 mt-4;
    }
  }
}
