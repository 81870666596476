.card {
  @apply w-full bg-white border border-gray-200 rounded-lg hover:shadow relative flex flex-col h-full;

  .image {
    @apply rounded-t-lg h-48 w-full object-cover;
  }

  .content {
    @apply p-5 flex flex-col flex-1;

    .title {
      @apply mb-2 text-2xl font-bold tracking-tight text-gray-800;
    }

    .text {
      @apply mb-4 font-normal text-gray-600 line-clamp-3 mt-auto;
    }

    .button {
      @apply w-fit inline-flex items-center;

      .svg {
        @apply rtl:rotate-180 w-3.5 h-3.5 ms-2;
      }
    }
  }
}
