.ImgContainer {
  @apply rounded-full ring-2 ring-gray-300 dark:ring-gray-500;
  max-width: 200px;
  max-height: 200px;
  overflow: hidden;
  cursor: pointer;

  img {
    transition:
      transform 0.3s,
      filter 0.3s;
  }

  img:hover {
    filter: brightness(80%);
    transform: scale(1.1);
  }
}
