.RMSVerticalInfoBlockContainer {
  @apply flex flex-col justify-center items-center w-full relative;

  .backgroundImage {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: -1;
  }

  .RMSVerticalInfoBlock {
    @apply flex flex-row gap-4 flex-wrap justify-center max-w-full mt-8;
  }
}
