.RMSDialog {
  @apply overflow-y-hidden overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full flex;
  z-index: 9999;

  &.close {
    .RMSDialog__Background:global {
      animation: fadeOutUp 1s;
    }
  }

  .RMSDialog__Background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #00000033;

    &:global {
      animation: fadeInDown 0.5s;
    }
  }

  .RMSDialog__Content {
    @apply relative p-4 w-full max-w-xl max-h-full;
  }

  @media (max-width: 500px) {
    justify-content: end;
  }
}
