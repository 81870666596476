.RMSPagePreviewElement {
  @apply flex flex-col overflow-hidden;
  width: 416px;
  flex-basis: 350px;
  flex-grow: 1;

  .ImageContainer {
    height: 200px;
    background: var(--rms-color-primary-500);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .MetaContainer {
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .Title {
      text-align: center;
    }

    .Preview {
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}
