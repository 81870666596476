.EmployeePage {
  .TechnologiesContainer {
    margin-top: -24px;
  }

  .SubHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0 16px;
    margin-top: -24px;
    flex-wrap: wrap;

    @media (max-width: theme('screens.md')) {
      margin-top: 0;
      .ContactElement {
        width: 100%;
      }
    }
  }

  .SkillsContainer {
    .Title {
      text-align: center;
      margin: 32px;
    }
    .Inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 32px;
      width: 100%;
    }
  }

  .EmployeeContainer {
    margin-top: 24px;

    .EmployeeDescription {
      & > * {
        @apply mt-4;
      }
    }

    .CVContainer {
      --year-container-width: 60px;
      width: 400px;
      max-width: 100%;

      @media (max-width: theme('screens.sm')) {
        width: 300px;
      }
    }

    .FloatingContainer {
      .ShowCaseButtonContainer {
        button {
          @apply flex flex-row justify-center items-center gap-2;
        }
      }
    }
  }
}
