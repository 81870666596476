.section {
  @apply w-full;

  .content {
    @apply max-w-screen-lg mx-auto px-4 sm:px-6 flex items-center;

    .figure {
      @apply py-4 lg:py-8 w-full mx-auto text-center;

      .svg {
        @apply w-10 h-10 mx-auto text-gray-400;

        &.top {
          @apply mb-4;
        }

        &.bottom {
          @apply mt-4 rotate-180;
        }
      }

      .blockquote {
        @apply text-2xl italic font-medium text-gray-800;
      }
    }
  }
}
