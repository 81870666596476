.header {
  @apply fixed w-full top-0;
  z-index: 9000;

  .nav {
    @apply bg-white border-gray-200 px-4 lg:px-6 py-2.5 shadow;

    .container {
      @apply flex flex-wrap justify-between items-center mx-auto max-w-screen-xl;

      .logoContainer {
        @apply flex items-center;

        .logo {
          @apply mr-3 h-6 sm:h-9;
        }

        .text {
          @apply self-center text-lg md:text-xl font-semibold whitespace-nowrap;
        }
      }

      .dropdownContainer {
        @apply flex items-center lg:order-2;

        .dropdownButton {
          @apply inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none;

          .dropdownIcon {
            @apply w-6 h-6;
          }
        }
      }

      .menu {
        @apply justify-between items-center w-full lg:flex lg:w-auto lg:order-2;

        .list {
          @apply flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 list-none pl-0;

          .defaultElement {
            @apply block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-color-primary-700 lg:p-0;
          }

          .selectedElement {
            @apply block py-2 pr-4 pl-3 text-white rounded bg-color-primary-700 lg:bg-transparent lg:text-color-primary-700 lg:p-0;
          }
        }
      }
    }
  }
}
