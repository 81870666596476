.section {
  @apply w-full;

  .content {
    @apply max-w-screen-lg mx-auto px-4 sm:px-6 flex items-center;

    .inner {
      @apply py-4 lg:py-8 w-full;

      .title {
        @apply mb-4 w-full text-center;
      }

      .subtitle {
        @apply mb-8 w-full text-gray-600 text-center;
      }

      .cards {
        @apply w-full grid grid-cols-1 gap-4;

        .cardRow {
          @apply w-full grid gap-4 place-items-center;

          &.cardRow1 {
            @apply grid-cols-1 lg:grid-cols-1;
          }

          &.cardRow2 {
            @apply grid-cols-1 lg:grid-cols-2;
          }

          &.cardRow3 {
            @apply grid-cols-1 lg:grid-cols-3;
          }

          .card {
            @apply h-full;
          }
        }
      }
    }
  }
}
