.RMSPagePreviewBlock {
  @apply w-full pb-4 lg:pb-8;

  .inner {
    @apply max-w-screen-lg w-full mx-auto px-4 sm:px-6 items-center flex flex-col gap-4;
  }

  .IndexContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 32px;
  }

  .buttonContainer {
    @apply w-full;

    .button {
      @apply w-full;
    }
  }
}
