.section {
  @apply w-full;

  .content {
    @apply max-w-screen-lg mx-auto px-4 sm:px-6 flex items-center;

    .inner {
      @apply py-4 lg:py-8 w-full;

      .title {
        @apply mb-4 w-full text-center;
      }

      .subtitle {
        @apply mb-8 w-full text-gray-600 text-center;
      }

      .form {
        @apply w-full grid grid-cols-1 md:grid-cols-2 gap-4;

        .element {
          @apply w-full;
        }

        .messageField {
          @apply md:col-span-2;
        }

        .input {
          @apply w-full;
        }

        .bottom {
          @apply md:col-span-2;

          .success {
            @apply text-color-primary-500;
          }

          .error {
            @apply text-red-500;
          }

          .success,
          .error,
          .loaderContainer {
            @apply w-full text-center;
          }

          .sendButton {
            @apply w-full;
          }
        }
      }
    }
  }
}

.RMSContactBlock {
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .Employee {
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .ContactElements {
        display: flex;
        flex-direction: column;
        justify-content: left;

        .Social {
          display: flex;
          justify-content: center;
          gap: 16px;
        }
      }
    }
  }

  .Form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    gap: 16px;
    max-width: 595px;

    .Inner {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.Right {
        width: 100%;
      }

      &.Left {
        width: 100%;
        flex-direction: row;
        gap: 16px;

        // media query smaller then 400px
        @media (max-width: 600px) {
          flex-wrap: wrap;

          > * {
            width: 100%;
          }
        }

        .EmailField {
          flex-grow: 1;
        }
      }
    }
  }
}
