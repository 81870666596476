.section {
  @apply bg-white w-full h-screen;

  .background {
    @apply absolute inset-0 z-0 bg-cover bg-center h-full opacity-40 overflow-hidden;
  }

  .container {
    @apply grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 h-full z-10 justify-center lg:justify-normal;

    .content {
      @apply mr-auto place-self-center lg:col-span-7 z-10 text-center md:text-left break-normal;

      .header {
        @apply max-w-2xl mb-6 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl hyphens-none;
      }

      .subheader {
        @apply max-w-2xl mb-4 text-gray-700 lg:mb-4 md:text-lg lg:text-xl;
      }

      .content {
        @apply max-w-2xl mb-8 text-gray-700 font-light md:text-lg lg:text-xl;
      }

      .buttons {
        @apply flex flex-col gap-4 md:block;

        .button {
          @apply inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-color-primary-700 hover:bg-color-primary-800 focus:ring-4 focus:ring-color-primary-300 cursor-pointer md:mr-4 md:mb-4;
        }

        .secondaryButton {
          @apply inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center  border border-gray-300 rounded-lg bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-100;
        }
      }
    }

    .imageContainer {
      @apply hidden lg:mt-0 lg:col-span-5 lg:flex z-10;

      .image {
        @apply object-contain;
      }
    }
  }
}
